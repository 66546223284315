import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import { postEmail } from "../../utils/api";
import Select from "react-select";
import { getCategories, getLocations } from "../../utils/api";

class EmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isOpen: false,
      fetchedCategories: [],
      fetchedLocations: [],
      categories: [],
      locations: [],
      email: null,
    };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.updateCategory = this.updateCategory.bind(this);
  }

  async fetchInitial() {
    await this.setState({ category: 0, location: 0 });
    const cat = await getCategories();
    const loc = await getLocations();
    this.setState({ fetchedCategories: cat, fetchedLocations: loc });
  }

  async componentDidMount() {
    try {
      this.setState({ ...this.state, isFetching: true });
      await this.fetchInitial();
      this.setState({ ...this.state, isFetching: false });
    } catch (error) {
      console.log(error);
    }
  }

  async toggle() {
    await this.setState({ isOpen: !this.state.isOpen });
  }

  async updateLocation(event) {

    let submitLocations = []
    event.map(({ value, label }, i) =>
        submitLocations.push(value)
    );

    if (submitLocations.includes(0)) {
      submitLocations = []
    }


    await this.setState({ locations: submitLocations });
  }

  async updateCategory(event) {
    let submitCategories = []
    event.map(({ value, label }, i) =>
        submitCategories.push(value)
    );

    if (submitCategories.includes(0)) {
      submitCategories = []
    }

    await this.setState({ categories: submitCategories });
  }

  async handleSubmit(event) {
    event.preventDefault();
    await this.setState({ isOpen: false });
    postEmail(this.state.email, this.state.categories, this.state.locations);
  }
  render() {
    let category_options = [];
    {
      this.state.fetchedCategories &&
        this.state.fetchedCategories.map(({ id, name }, i) =>
          category_options.push({ value: id, label: name })
        );
    }
    category_options.unshift({ value: 0, label: "All" });

    let location_options = [];
    {
      this.state.fetchedLocations &&
        this.state.fetchedLocations.map(({ id, name }, i) =>
          location_options.push({ value: id, label: name })
        );
    }
    location_options.unshift({ value: 0, label: "All" });

    return (
      <>
        <a
          onClick={this.toggle}
          class="btn msround bg-blu2 btn-rounded klmc-button"
        >
          {this.props.title}
        </a>

        <Modal show={this.state.isOpen} backdrop="static">
          <Modal.Header>
            <Modal.Title>Be the first to know about new events!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
            Sign up to receive automated email notifications when new events are listed. 
            </p>
            <form onSubmit={this.handleSubmit} id="myForm">
              <label for="category"><strong>Where would you attend events?</strong>
              <p class="more-info">Note: Online is an option.</p>
              </label>
              {/* <select
                id="category"
                name="category"
                onChange={this.updateCategory}
              >
                {category_options &&
                  category_options.map(({ value, label }, i) => (
                    <option value={value}>{label}</option>
                  ))}
              </select> */}
              <Select
                id="emailBasic-single"
                className="basic-single"
                defaultValue={this.state.location}
                isSearchable={true}
                isMulti
                name="color"
                options={location_options}
                onChange={this.updateLocation}
              />
              <br></br>

              <label for="location"><strong>What interests you?</strong></label>
              <Select
                id="emailBasic-single"
                className="basic-single"
                defaultValue={this.state.category}
                isSearchable={true}
                isMulti
                name="color"
                options={category_options}
                onChange={this.updateCategory}
              />
              <br></br>
              <label for="email"><strong>Email: </strong></label>
              <br />
              <input
                id="Nemail"
                name="email"
                type="email"
                required
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={this.toggle}>
              Close
            </Button>
            <Button variant="success" type="submit" form="myForm">
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default EmailModal;
