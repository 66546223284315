import React, { Component } from "react";
import {
  getCategories,
  getLocations,
  getMonthEvents,
  getEventsFilter,
} from "../../utils/api";
import moment from "moment";
import NextPreviousButtons from './NextPrevious';
import LogoBanner from "./LogoBanner";
import FilterSelect from "./FilterSelect";
import FlipCard from './FlipCard';
var date = new Date();

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      events: [],
      categories: [],
      locations: [],
      category: null,
      location: null,
      start_date: null,
      end_date: null,
      current_date: null,
    };
    this.updateCategory = this.updateCategory.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.previousMonth = this.previousMonth.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.scrollToEvents = this.scrollToEvents.bind(this);
  }
  // ==================================================================================
  async fetchInitial() {
    const date = new Date(); // assuming date is defined or passed as argument
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    endDate.setHours(23, 59, 59, 999); // Set hours, minutes, seconds, and milliseconds to end of day


    // log start and end dates
    console.log("start date: " + date.toISOString());
    console.log("end date: " + endDate.toISOString());
  
    await this.setState({
      current_date: date,
      category: { value: null, label: "All" },
      location: { value: null, label: "All" },
      start_date: date,
      end_date: endDate,
    });
  
    const resp = await getMonthEvents(
      date.toISOString(),
      endDate.toISOString()
    );
    // console.log(resp)
    const cat = await getCategories();
    const loc = await getLocations();
  
    this.setState({
      events: resp,
      categories: cat,
      locations: loc,
    });
  }
  // ==================================================================================
  async componentDidMount() {
    try {
      this.setState({ ...this.state, isFetching: true, current_date: date });
      await this.fetchInitial();
      this.setState({ ...this.state, isFetching: false });
    } catch (error) {
      console.log(error);
    }
  }


  // scroll to start of events
  scrollToEvents = () => {
    window.scrollTo({
      top: 450,
      behavior: "smooth",
    });
  };

  // TODO -- CLEAN UP CODE AND OPTIMIZE
    // ==================================================================================
  async filter() {
    var builder = "/events";
    if (this.state.category.value != null && this.state.location.value != null) {
      builder += `?categories.id_in=${this.state.category.value}&location.id_in=${this.state.location.value}&start_datetime_gte=${this.state.start_date.toISOString()}`;
    } else {
      if (this.state.category.value != null) {
        builder += `?categories.id_in=${this.state.category.value}&start_datetime_gte=${this.state.start_date.toISOString()}`;
      } else if (this.state.location.value != null) {
        builder += `?location.id_in=${this.state.location.value}&start_datetime_gte=${this.state.start_date.toISOString()}`;
      }
  
      if (this.state.category.value == null && this.state.location.value == null) {
        // Use '?' instead of '&' to start query parameters
        builder += `?start_datetime_lte=${this.state.end_date.toISOString()}&end_datetime_gte=${this.state.start_date.toISOString()}`;
      }
    }

  

    this.setState({ isFetching: true });
    const newEvents = await getEventsFilter(builder);
    await this.setState({ events: newEvents, isFetching: false });

    this.scrollToEvents();
  }
  // ==================================================================================
  async updateCategory(inputValue) {
    await this.setState({ category: inputValue });
    this.filter();
  }
  // ==================================================================================
  async updateLocation(inputValue) {
    await this.setState({ location: inputValue });
    this.filter();
  }
  // ==================================================================================
  async nextMonth() {
    let nextMonthDate = new Date(
      this.state.current_date.getFullYear(),
      this.state.current_date.getMonth() + 1,
      1
    );

    if (nextMonthDate.getMonth() + 1 == date.getMonth() + 1) {
      nextMonthDate = date;
    }

    let endMonthDate = new Date(
      nextMonthDate.getFullYear(),
      nextMonthDate.getMonth() + 1,
      0
    );
    endMonthDate.setHours(24,0,0,0);

    await this.setState({
      start_date: nextMonthDate,
      end_date: endMonthDate,
      current_date: nextMonthDate,
    });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.filter();
  }
  // ==================================================================================
  async previousMonth() {
    let previousMonthDate = new Date(
      this.state.current_date.getFullYear(),
      this.state.current_date.getMonth() - 1,
      1
    );

    if (previousMonthDate.getMonth() + 1 == date.getMonth() + 1) {
      previousMonthDate = date;
    }

    let endMonthDate = new Date(
      previousMonthDate.getFullYear(),
      previousMonthDate.getMonth() + 1,
      0
    );
    endMonthDate.setHours(24,0,0,0);

    await this.setState({
      start_date: previousMonthDate,
      end_date: endMonthDate,
      current_date: previousMonthDate,
    });
    
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.filter();
  }



  // ==================================================================================

  async resetFilter() {
    await this.setState({
      category: { value: null, label: "All" },
      location: { value: null, label: "All" },
    });
    this.filter();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    if (this.state.isFetching) {
      return (
        <div class="">
          <div class="">
            <LogoBanner />
            <h1 className="loading-events">Loading Events ...</h1>
          </div>
        </div>
      );
    } else {

      return (
        <div>
          <LogoBanner />

          <div className="header-items">
          <div class="filter-box"> 
          
          <NextPreviousButtons 
            start_date={this.state.start_date?.getMonth()}  
            date={date} nextMonth={this.nextMonth} 
            previousMonth={this.previousMonth}
            monthTitle={moment(this.state.start_date).format("MMMM YYYY")}/> 
          </div>
          
          <FilterSelect 
            categories={this.state.categories}
            category={this.state.category}
            updateCategory={this.updateCategory}
            locations={this.state.locations}
            location={this.state.location}
            updateLocation={this.updateLocation}
            resetFilter={this.resetFilter}
          />

        </div>

        {/* count how many this.state.events */}
        <div className="resultsNumber">{this.state.events ? this.state.events.length: "0"} results </div>

        <hr className="page-divider"/>
          
          <div class="cardParent">
            {this.state.events &&
              this.state.events.map(
                (
                  {
                    id,
                    title,
                    location,
                    start_datetime,
                    end_datetime,
                    description,
                    banner,
                    event_url,
                    host,
                    categories,
                  },
                  i
                ) => (
                  <FlipCard
                   id={id}
                   title={title}
                   location={location}
                   start_datetime={start_datetime}
                   end_datetime={end_datetime}
                   description={description}
                   banner={banner}
                   event_url={event_url}
                   host={host}
                   categories={categories}
                   />
                )
              )}

            {this.state.events?.length == 0 && <h1 class="no-events"> No Events</h1>}
          </div>

          {this.state.events?.length > 3 && 
          <div className="bottom-buttons">
            <NextPreviousButtons start_date={this.state.start_date?.getMonth()}  date={date} nextMonth={this.nextMonth} previousMonth={this.previousMonth} /> 
          </div>}
          
        </div>
      );
    }
  }
}

export default EventList;
