import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";
import ReactCardFlipper from "react-card-flipper";
import injectSheet from "react-jss";
import moment from "moment";
import ReactMarkdown from "react-markdown";
var reactShare = require("react-share");
import nobanner from '../../assets/img/events/no-banner.jpg'


function FlipCardS({id,
    title,
    location,
    start_datetime,
    end_datetime,
    description,
    banner,
    event_url,
    host,
    categories}) {

    let cardHeight = 425;

    if (window.innerWidth < 425) {
      cardHeight = window.innerWidth;
    }

    return (

        <div class="cardHolder " title="Click for more info">
                    <ReactCardFlipper
                      width={cardHeight}
                      height="350px"
                      behavior="click"
                      levitate={true}
                      className={styles.root}
                      innerCardClass={styles.card}
                    >
                      <div class="card-front">
                        <div class="cardInformation">
                          <div class="center">
                            <h2 class="msround">{title}</h2>
                            <p class="mscond">
                              {location.name}{" "}
                              <br />
                              {
                              moment(start_datetime).format("ddd, MMM DD, h:mm A")
                            }{" "}
                            {moment(end_datetime).isSame(start_datetime, "day")
                              ? `- ${moment(end_datetime).format("h:mm A")}`
                              : `- ${moment(end_datetime).format("ddd, MMM DD, h:mm A")}`}
                            </p>
                          </div>

                          <div className="cardImageDiv">
                          <img
                            className="cardImage"
                            src={(() => {
                              if (banner != null) {
                                return (
                                  `https://keeplearningmc.nz/backend` +
                                  banner.url
                                );
                              } else {
                                return nobanner;
                              }
                            })()}
                            alt=""
                          />
                          </div>
                        </div>
                      </div>
                      <div class="cardContents">
                        <div class="cardInformation overflow-auto text-wrap card-back">
                          <p>
                            {" "}
                            <strong>
                              {moment(start_datetime).format(
                                "ddd, MMM DD, h:mm A"
                              )}{" "}
                              - {moment(end_datetime).format("MMM DD, h:mm A")}
                            </strong>
                          </p>
                          <p>
                            <strong>Host: </strong> {host}
                          </p>
                          <p>
                            <strong>Category: </strong>
                            {(() => {
                              if (categories.length != 0) {
                                let catString = "";

                                for (let i = 0; i < categories.length; i++) {
                                  catString += categories[i].name + ", ";
                                }

                                return catString;
                              } else {
                                return `No categoires`;
                              }
                            })()}
                          </p>
                          <p>
                            <strong>Location: </strong> {location.name}
                          </p>
                          <p>
                            <strong>Description: </strong>{" "}
                            <ReactMarkdown>{description}</ReactMarkdown>
                          </p>

                          <p>
                            <strong>Share: </strong>
                          </p>

                          <a
                            class="link-button"
                            onClick={() => {
                              var clipboard = navigator?.clipboard
                              clipboard?.writeText(event_url)
                            }}
                          >
                            <img
                              src="https://cdn-icons-png.flaticon.com/512/181/181531.png"
                              alt="Copy Link"
                              width="40px"
                              height="40px"
                            />
                          </a>
                          <EmailShareButton url={event_url}>
                            <reactShare.EmailIcon></reactShare.EmailIcon>
                          </EmailShareButton>

                          <FacebookShareButton url={event_url}>
                            <reactShare.FacebookIcon></reactShare.FacebookIcon>
                          </FacebookShareButton>

                          <TwitterShareButton url={event_url}>
                            <reactShare.TwitterIcon></reactShare.TwitterIcon>
                          </TwitterShareButton>

                          <WhatsappShareButton url={event_url}>
                            <reactShare.WhatsappIcon></reactShare.WhatsappIcon>
                          </WhatsappShareButton>
                        </div>
                        <a
                          href={event_url}
                          type="button"
                          target="_blank"
                          class="btn msround bg-blu2 btn-rounded klmc-button infoBtn"
                        >
                          More Information
                        </a>
                      </div>
                    </ReactCardFlipper>
                  </div>

    )
}

const styles = {
    root: {
      display: "block",
      margin: "40px",
      position: "relative",
    },
    card: {
      border: "1px solid #ccc",
      borderRadius: "8px",
      padding: "10px",
      backgroundColor: "#000000 !important",
    }
  
  };

const FlipCard = injectSheet(styles)(FlipCardS)

export default FlipCard;