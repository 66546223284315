import React, { Component } from "react";
import pic1 from "../../assets/img/strapi-pic-1.png";
import pic2 from "../../assets/img/strapi-pic-2.png";
import pic3 from "../../assets/img/strapi-pic-3.png";
import pic4 from "../../assets/img/strapi-pic-4.png";
import pic5 from "../../assets/img/strapi-pic-5.jpg";
import login from "../../assets/img/strapi-login.jpg";
import adminpanel from "../../assets/img/adminpanel.jpg";

class HowTo extends Component {
  render() {
    return (
      <div class="container block">
        <div class="row row-center">
          <h1 class="msround display-3 py-4">How to use Strapi</h1>
        </div>
        <div class="row row-eq-height mb-5">
          <p class="pt-4 mb-0">
            Strapi is the backend framework that keep learning midcanterbury
            staff use to manage https://keeplearningmc.nz/.
            <br />
            <br />
            To access the Strapi backend panel navigate to
            https://keeplearningmc.nz/backend/admin
            <br />
            <br />
            <img class="pic" src={login} />
          </p>

          <div class="col-sm-7">
            <p>
              Once you are logged in you will be taken to an interface,
              depending on your authority you will have more or less options
              available for you. First we will take a look at what an admin can
              do.
              <br />
              <br />
              <img class="pic1" src={adminpanel} />
              <br />
              <br />
              The first panel we will look at is Collection Type: Categories:
              <br />
              <br />
              <img class="pic1" src={pic1} />
              <br />
              <br />
              From the categories panel we can add any categories to our events
              i.e Pottery
              <br />
              <br />
              <img class="pic1" src={pic2} />
              <br />
              <br />
              The category can then be used to add to events. Once the category
              is added we can add an event in the Events panel:
              <br />
              <br />
              <img class="pic1" src={pic3} />
              <br />
              <br />
              The Location panel is similar to the Categories in that it
              supplements the Event by adding a location to the event which you
              can see down the right hand side of the Events panel
              <br />
              <br />
              <img class="pic1" src={pic4} />
              <br />
              <br />
              In the events panel click Add New Events and the events creation
              interface will pop up. Here you create events by filling out a
              form which will tell us the events title, start date, end date,
              description, address, url, host, start time, end time and an
              image. Click Save and then Publish.
              <br />
              <br />
              <img class="pic1" src={pic5} />
              <br />
              <br />
              The event will appear on the calendar of the website on the day
              you set it to start. From there the event can be viewed and shared
              to various social media platforms.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default HowTo;
