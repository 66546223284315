import React, { Component } from "react";
import EmailModal from "../EmailModal/index";
import cmclogo from "../../assets/img/branding/cmc-logo.png";
class Footer extends Component {
  render() {
    return (
      <footer class="page-footer font-small pt-4 bg-grn text-white">
        <div class="container text-center">
          <div class="row">
            <div class="col-md-8 mx-auto">
              <h5 class="msround text-uppercase mt-3 mb-4">
                Keep Learning Mid Canterbury
              </h5>
              <p>
              Keep Learning Mid Canterbury is an initiative of Connecting Mid Canterbury Charitable Trust. The www.keeplearningmc.nz website is free for groups, organisations and businesses to promote their educational events so all residents in the district can easily find opportunities to 'Keep Learning'.
              <br />
              <br />
              If you can't find an event you're after, try searching the <a href="https://www.cab.org.nz/search/#qa=0&sp=1&ir=0&wc=0&locality=Canterbury&city=Ashburton" target="_blank">Community Directory</a> for local groups and organisations that might be able to help.
              </p>
            </div>
          </div>
        </div>
        {/* <hr /> */}
        <ul class="list-unstyled list-inline text-center py-2">
          <li class="list-inline-item">
            <a
              href="https://donorbox.org/keep-learning-midcanterbury"
              target="_blank"
              class="btn msround bg-blu2 btn-rounded klmc-button"
            >
              Donate
            </a>
          </li>
          <li class="list-inline-item">
            <a
              href="/submit"
              class="btn msround bg-blu2 btn-rounded klmc-button"
            >
              Add Event
            </a>
          </li>
          <li class="list-inline-item">
            <EmailModal title="Email Notifications"/>
          </li>
        </ul>
        {/* <hr /> */}
        <div class="footer-copyright text-center py-3">
          © 2021 Copyright  
          <a  href="https://connectingmc.wixsite.com/home" target="_blank"><img class='cmclogo' src={cmclogo}/> </a>
          </div>
      </footer>
    );
  }
}

export default Footer;
