import Select from "react-select";
import ResetFilter from "./ResetFilter";

export default function FilterSelect({categories, category, updateCategory, locations, location, updateLocation, resetFilter}) {

    let category_options = [];
      {
        categories &&
          categories.map(({ id, name }, i) =>
            category_options.push({ value: id, label: name })
          );
      }
      category_options.unshift({ value: null, label: "All" });

      let location_options = [];
      {
        locations &&
          locations.map(({ id, name }, i) =>
            location_options.push({ value: id, label: name })
          );
      }
      location_options.unshift({ value: null, label: "All" });


    return (

        <div className="filter-padding">

        <h5 className="filters-text">Filters</h5>

        <div class="filter-box">
            <div class="form-group location-padding">
            <label>Category</label>
            <Select
                className="basic-single"
                defaultValue={category}
                isSearchable={true}
                options={category_options}
                onChange={updateCategory}
            />
            </div>
            <div class="form-group location-padding">
            <label>Location</label>
            <Select
                className="basic-single"
                defaultValue={location}
                isSearchable={true}
                name="color"
                options={location_options}
                onChange={updateLocation}
            />
            </div>

        </div>
        {category?.label != "All" || location?.label != "All" ? <div class="form-group "><ResetFilter resetFilter={resetFilter} /></div> : ""}
        </div>

    )
}
