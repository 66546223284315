import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";
import EmailModal from "../EmailModal";

const FirstVisitModal = (isOpenProp) => {

  const [isOpen , setIsOpen] = useState(isOpenProp);

  const maybeLaterYes = async () => {
    closeModal();
    await localStorage.setItem("maybeLater", "true");
  }

  const maybeLaterNo = async () => {
    closeModal();
    await localStorage.setItem("maybeLater", "false");
  }

  const signUp = async () => {
    await localStorage.setItem("maybeLater", "false");
  }

  const closeModal = async () => {
    await setIsOpen(false);
  }

  

    return (
      <>
        <Modal show={isOpen} backdrop="static">
          <Modal.Header>
            <Modal.Title>Be the first to know about future events!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
            Sign up for notifications about events that interest you. 
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={maybeLaterNo}>
              No Thanks
            </Button>
            <Button variant="danger" onClick={maybeLaterYes}>
              Maybe Later
            </Button>
            <Button variant="success" onClick={maybeLaterNo}>
              Already Done
            </Button>
            <a onClick={signUp}>
            <EmailModal title="Yes Please" />
            </a>

          </Modal.Footer>
        </Modal>
      </>
    );
  }

export default FirstVisitModal;
