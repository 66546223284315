import React, { Component } from "react";
import logo from "../../assets/img/branding/logo-transparent.png";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import EmailModal from "../EmailModal/index";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
    };
    this.toggle = this.toggle.bind(this);
  }

  async toggle() {
    await this.setState({ modal: !this.state.modal });
  }

  render() {
    return (
      <nav class="navbar navbar-expand-lg navbar-dark bg-grn main-navigation-shadow">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img class="nav-logo" src={logo} />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav mscond">
              <a class="nav-link" href="/about">
                About
              </a>
              <a class="nav-link" aria-current="page" href="/">
                Events
              </a>
              <a class="nav-link" href="/blogs">
                Past Events
              </a>
              <a
                class="nav-link"
                target="_blank"
                href="https://donorbox.org/keep-learning-midcanterbury"
                tabindex="-1"
                aria-disabled="true"
              >
                Donate
              </a>
            </div>
            <div class="navbar-nav mscond">
              <ul class="list-unstyled list-inline text-center m-0">
                <li class="list-inline-item">
                  <a
                    href="/submit"
                    class="btn msround bg-blu2 btn-rounded klmc-button"
                  >
                    Add Event
                  </a>
                </li>
                <li class="list-inline-item">
                  <EmailModal title="Email Notifications" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
