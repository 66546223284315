export default function ResetFilter({resetFilter}) {

    return (
        <div class="reset-button">
            {/* <h1 class="month">All Results</h1> */}
            <a
            onClick={resetFilter}
            class="btn msround bg-blu2 btn-rounded klmc-button"
            >
            Reset Filter
            </a>
      </div>
    )
}