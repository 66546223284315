import React, { Component } from "react";
import ways from "../../assets/img/ways.jpg";
import flax from "../../assets/img/flaxweaving.JPG"
import { getFunders } from "../../utils/api";
import ReactMarkdown from "react-markdown";

class AboutComp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            funders: {}
        }
    }


    async componentDidMount() {
        try {
            this.setState({ isFetching: true });
            let data = await getFunders();
            this.setState({ funders: data, isFetching: false });
        } catch (error) {
            console.log(error);
        }
    }

    render() {

        if (this.state.isFetching) {
            return (
                <div class="container block">
                    <div class="row row-center">
                        <h1 class='msround display-3 py-4'>About Us</h1>
                    </div>
                    <div>
                        <h1>Loading ...</h1>
                    </div>
                </div>
            )
        }
        return (
            <div class="container block">
                <div class="row row-center">
                    <h1 class='msround display-3 py-4'>About Us</h1>
                </div>
                <div class="row row-eq-height mb-5">
                    <p class="pt-4 mb-0">
                        Keep Learning Mid Canterbury (formerly the Learning Exchange) is an initiative of <a target="blank_" href='https://connectingmc.wixsite.com/home'>Connecting
                            Mid Canterbury Charitable Trust</a>, founded in 2017. The Trust's mission is to support the growth of a regenerative, resilient and
                        caring community by connecting people, services and resources in Mid Canterbury.<br /><br />

                        'Keep Learning' is one of the 5 Ways to Wellbeing, recommended by the Mental Health Foundation of New Zealand.
                        Through Keep Learning Mid Canterbury and the Trust's other initiative; <a target="blank_" href='https://www.connectingmc.org'>Mid Canterbury TimeBank</a>,
                        we hope to support people in our district to put the the 5 Ways to Wellbeing into practice. These are Give, Be Active, Keep Learning, Connect and Take Notice.<br /><br />
                        <a target="blank_" href='https://mentalhealth.org.nz/five-ways-to-wellbeing'><img class='ways' src={ways} /></a>
                    </p>
                    <div class='col-sm-5'><img class='flax' src={flax} /></div>
                    <div class='col-sm-7'>
                        <p>
                            We put together a programme of around 40 Keep Learning events a year (on everything from Korean cooking to
                            suicide prevention, Zero Waste living, travel talks, flax weaving, astronomy, gardening and much more). These
                            events are open to the whole community, usually run by volunteers with expertise and a willingness to share
                            their passion, often free or with a small fee to cover expenses.<br /><br />

                            As of October 2021 Keep Learning Mid Canterbury has expanded to include this website where not only our own
                            events are listed, but anyone in Mid Canterbury can list their learning events.<br /><br />

                            Our desire with this website is that it will serve to connect people throughout Mid Canterbury to the many
                            wonderful resources and learning opportunities available in our community and help small groups and organisations
                            get the word out about their learning events.<br /><br />
                            Most events are held in Ashburton but at times we run events in Rakaia, Dorie, Hinds, Methven, Staveley and Mt Somers.
                            <br /> <br />
                        </p>
                        <a target="_blank" class="dono" href="https://donorbox.org/keep-learning-midcanterbury">
                        <img src="https://donorbox.org/images/png-donate/button-medium-blue.png" /></a>
                    </div>
                </div>


                <div class="row row-center mb-5 text-center">

                        <h1 class='msround display-5 pt-5'>Our Funders: </h1>
                        <div><strong>We are grateful to the following funders for their support of Keep Learning Mid Canterbury:</strong></div>
                        < br />< br />< br />
                        <div><ReactMarkdown>{this.state.funders.content}</ReactMarkdown></div>
                </div>
            </div>
        );
    }
}

export default AboutComp;