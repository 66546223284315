import React, { useState } from "react";
import { getPostsPagination, getPostCount } from "../../utils/api";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class BlogList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isOpen: false,
      posts: [],
      currentPage: 1,
      postsPerPage: 3,
      start: 0,
      totalPosts: 0
    };

    this.paginateFront = this.paginateFront.bind(this)
    this.paginateBack = this.paginateBack.bind(this)
  }

  componentDidMount() {
    this.updatePosts()
  }

  async updatePosts() {
    this.setState({ isFetching: true });
    await getPostCount().then(count => {
      this.setState({ totalPosts: count });
    })
    await getPostsPagination(this.state.start, this.state.postsPerPage).then(data => {
      this.setState({ posts: data, isFetching: false });
    })
  }

  async paginateFront() {
    await this.setState({ currentPage: this.state.currentPage + 1, start: this.state.start + this.state.postsPerPage })
    this.updatePosts()
    window.scrollTo(0, 0)
  };

  async paginateBack() {
    await this.setState({ currentPage: this.state.currentPage - 1, start: this.state.start - this.state.postsPerPage })
    this.updatePosts()
    window.scrollTo(0, 0)
  };

  render() {

    if (this.state.isFetching) {
      return (
        <div class="container block">
          <div class="row row-center">
            <h1 class="msround display-3 py-4">Past Events</h1>
          </div>
          <div>
            <h1>Loading Blogs ...</h1>
          </div>
        </div>
      )
    }
    return (
      <>
        <div class="container block">
          <div class="row row-center">
            <h1 class="msround display-3 py-4">Past Events</h1>
          </div>
          <div>
            {this.state.posts &&
              this.state.posts.map(
                (
                  { id, Title, description, created_at, image, gallery, updated_at },
                  i
                ) => (

                  <div class="blog-post">
                    <div class="card">

                      <div className="image-parent">
                        <img
                          class="card-img-top"
                          src={"https://keeplearningmc.nz/backend" + image.url}
                          alt="Blog Image"
                        />
                      </div>
                      <div class="card-body">
                        <br /><br />
                        <h3 class="card-title text-center">{Title}</h3>
                        <p class="card-text">
                          <ReactMarkdown>{description}</ReactMarkdown>
                        </p>
                        <br /><br /><br />

                        <Carousel autoPlay
                          swipeable={true}
                          useKeyboardArrows={true}
                          emulateTouch={true}
                          infiniteLoop={true}
                          dynamicHeight={true}>

                          {gallery &&
                            gallery.map(
                              (
                                { formats },
                                i
                              ) => (

                                <div>
                                  <img alt="" class="responsive-image" src={"https://keeplearningmc.nz/backend" + formats.medium.url} />
                                </div>
                              )
                            )}
                        </Carousel>




                      </div>
                      <div class="card-footer blog-footer">
                        <small>
                          {moment(created_at).format("ddd, MMM DD, h:mm A")}
                        </small>
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>
          <div class="pagination">
            <h5 className="font-bold">Page {this.state.currentPage} / {Math.ceil(this.state.totalPosts / this.state.postsPerPage)}</h5>
            <div>
              <button className="post-button" onClick={this.paginateBack} disabled={this.state.currentPage <= 1}> Previous Page </button>
              <button className="post-button" onClick={this.paginateFront} disabled={this.state.currentPage >= Math.ceil(this.state.totalPosts / this.state.postsPerPage)}> Next Page </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BlogList;
