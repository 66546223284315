
import moment from "moment";

export default function NextPreviousButtons({start_date, date, nextMonth, previousMonth, monthTitle}) {

        if (start_date == date.getMonth()) {

            return (
                
            <div class="filter-box">
                <li class="list-inline-item">
                <h1 class="month">{monthTitle} </h1>
                  <a
                    onClick={nextMonth}
                    class="btn msround dirBtn bg-blu2 btn-rounded klmc-button"
                  >
                    Next Month ❯
                  </a>
                </li>
              </div>
            )
  
          } else {
            return (
              <div class="filter-box">
  
                <div class=" monthButton">
                  <a
                    onClick={previousMonth}
                    class="btn msround dirBtn bg-blu2 btn-rounded klmc-button"
                  >
                    ❮ Previous Month
                  </a>
                </div>

                <h1 class="month">{monthTitle}</h1>
  
                <div class=" monthButton">
                  <a
                    onClick={nextMonth}
                    class="btn msround dirBtn bg-blu2 btn-rounded klmc-button "
                  >
                    Next Month ❯
                  </a>
                </div>
              </div>
            );
          }

}